import { createSlice } from '@reduxjs/toolkit';
import { findIndex } from 'lodash';
import Api from '../../../lib/api';

const apiGetFiltersDefault = {
  include: [],
  limit: 0,
  page: 0,
  params: [],
  filters: [],
  search: null,
  sort: null,
};
/* const apiUrlDataDefault = {
  module: null,
  entity: null,
  entities: null,
  many: false,
}; */

export function newSlice(entitiesName) {
  const entitiesNamePagination = `${entitiesName}Pagination`;
  const getLoading = `${entitiesName}Loading`;
  const saveLoading = `${entitiesName}SaveLoading`;
  const deleteLoading = `${entitiesName}DeleteLoading`;
  const entitiesNameErrors = `${entitiesName}Errors`;
  const initialState = {
    loading: false,
    [getLoading]: false,
    [saveLoading]: false,
    [deleteLoading]: false,
    errors: [],
    [entitiesName]: [],
    [entitiesNamePagination]: {},
    [entitiesNameErrors]: [],
  };
  return createSlice({
    name: entitiesName,
    initialState,
    reducers: {
      getEntities: (state, { payload }) => {
        const showPageLoading = payload;
        state.loading = showPageLoading === true ? true : state.loading;
        state[getLoading] = true;
      },
      getEntitiesSuccess: (state, { payload }) => {
        state[entitiesName] = payload.data;
        state[entitiesNamePagination] = payload.meta != null ? payload.meta.pagination : {};
        state.loading = false;
        state[getLoading] = false;
        state.errors = [];
        state[entitiesNameErrors] = state.errors;
      },
      getEntitiesFailure: (state, { payload }) => {
        state.loading = false;
        state[getLoading] = false;
        state.errors = payload.data != null ? [...state.errors, payload.data.message] : state.errors;
        state[entitiesNameErrors] = state.errors;
        console.log('ERROR', payload.message);
      },
      createEntity: (state) => {
        state[saveLoading] = true;
      },
      createEntitySuccess: (state, { payload }) => {
        state[entitiesName] = [payload.data, ...state[entitiesName]];
        state.loading = false;
        state[getLoading] = false;
        state[saveLoading] = false;
        state.errors = [];
        state[entitiesNameErrors] = state.errors;
      },
      createEntityFailure: (state, { payload }) => {
        state.loading = false;
        state[getLoading] = false;
        state[saveLoading] = false;

        state.errors = payload.data != null ? [...state.errors, payload.data.message] : state.errors;
        state[entitiesNameErrors] = state.errors;
      },
      updateEntity: (state) => {
        state[saveLoading] = true;
      },
      updateEntitySuccess: (state, { payload }) => {
        const index = findIndex(state[entitiesName], ['id', payload.data.id]);
        const entity = { ...state[entitiesName][index], ...payload.data };

        const entities = [...state[entitiesName]];
        entities[index] = entity;

        state[entitiesName] = [...entities];
        state.loading = false;
        state[getLoading] = false;
        state[saveLoading] = false;
        state.errors = [];
        state[entitiesNameErrors] = state.errors;
      },
      updateEntityFailure: (state, { payload }) => {
        state.loading = false;
        state[getLoading] = false;
        state[saveLoading] = false;
        state.errors = payload.data != null ? [...state.errors, payload.data.message] : state.errors;
        state[entitiesNameErrors] = state.errors;
      },

      addOnlyEntity: (state) => {
        state[saveLoading] = true;
      },
      addOnlyEntitySuccess: (state, { payload }) => {
        state[entitiesName] = [payload.data, ...state[entitiesName]];
        state.loading = false;
        state[getLoading] = false;
        state[saveLoading] = false;
        state.errors = [];
        state[entitiesNameErrors] = state.errors;
      },
      addOnlyEntityFailure: (state) => {
        state.loading = false;
        state[getLoading] = false;
        state[saveLoading] = false;
      },
      updateOnlyEntity: (state) => {
        state[saveLoading] = true;
      },
      updateOnlyEntitySuccess: (state, { payload }) => {
        const index = findIndex(state[entitiesName], ['id', payload.data.id]);
        const entity = { ...state[entitiesName][index], ...payload.data };

        const entities = [...state[entitiesName]];
        entities[index] = entity;

        state[entitiesName] = [...entities];
        state.loading = false;
        state[getLoading] = false;
        state[saveLoading] = false;
        state.errors = [];
        state[entitiesNameErrors] = state.errors;
      },
      updateOnlyEntityFailure: (state) => {
        state.loading = false;
        state[getLoading] = false;
        state[saveLoading] = false;
      },

      deleteEntity: (state) => {
        state[deleteLoading] = true;
        state[getLoading] = true;
      },
      deleteEntitySuccess: (state, { payload }) => {
        state[entitiesName] = state[entitiesName].filter((entity) => entity.id !== payload);
        state.loading = false;
        state[getLoading] = false;
        state[deleteLoading] = false;
      },
      deleteEntityFailure: (state, { payload }) => {
        state.loading = false;
        state[getLoading] = false;
        state[deleteLoading] = false;
        state.errors = payload.data != null ? [...state.errors, payload.data.message] : state.errors;
        state[entitiesNameErrors] = state.errors;
      },

      addLiveNotifications: (state) => {},
      addLiveNotificationsSuccess: (state, { payload }) => {
        const liveNotifications = { ...state[entitiesName] };
        const notificationsUnread = liveNotifications.notifications_unread;
        liveNotifications.notifications_unread = [
          payload.data,
          ...notificationsUnread.map((currentTask) => {
            return { ...currentTask };
          }),
        ];
        liveNotifications.notifications_unread_count += 1;
        state[entitiesName] = { ...liveNotifications };
      },
      addLiveNotificationsFailure: (state, { error }) => {},

      addUnreadToPaginationEntity: (state) => {},
      addUnreadToPaginationEntitySuccess: (state, { payload }) => {
        // console.log("addUnreadToPaginationEntitySuccess", payload);
        if (payload === 0) {
          state[entitiesNamePagination].unread = 0;
        } else {
          state[entitiesNamePagination].unread += 1;
        }
      },
      addUnreadToPaginationEntityFailure: (state, { error }) => {},

      unreadAllLiveNotifications: (state) => {},
      unreadAllLiveNotificationsSuccess: (state) => {
        const liveNotifications = { ...state[entitiesName] };
        const notificationsUnread = liveNotifications.notifications_unread;
        const notificationsViewed = liveNotifications.notifications_viewed;

        liveNotifications.notifications_viewed = [
          ...notificationsUnread.map((currentTask) => {
            return { ...currentTask, view: 1 };
          }),
          ...notificationsViewed.map((currentTask) => {
            return { ...currentTask };
          }),
        ];
        liveNotifications.notifications_unread = [];
        liveNotifications.notifications_unread_count = 0;

        state[entitiesName] = { ...liveNotifications };
      },
      unreadAllLiveNotificationsFailure: (state, { error }) => {},
    },
  });
}

export function _loadEvents(entitiesSlice) {
  const {
    getEntities,
    getEntitiesSuccess,
    getEntitiesFailure,
    createEntity,
    createEntitySuccess,
    createEntityFailure,
    updateEntity,
    updateEntitySuccess,
    updateEntityFailure,
    addOnlyEntity,
    addOnlyEntitySuccess,
    addOnlyEntityFailure,
    updateOnlyEntity,
    updateOnlyEntitySuccess,
    updateOnlyEntityFailure,
    deleteEntity,
    deleteEntitySuccess,
    deleteEntityFailure,
    addUnreadToPaginationEntity,
    addUnreadToPaginationEntitySuccess,
    addUnreadToPaginationEntityFailure,
    unreadAllLiveNotifications,
    unreadAllLiveNotificationsSuccess,
    unreadAllLiveNotificationsFailure,
    addLiveNotifications,
    addLiveNotificationsSuccess,
    addLiveNotificationsFailure,
  } = entitiesSlice.actions;
  return {
    getEntities,
    getEntitiesSuccess,
    getEntitiesFailure,
    createEntity,
    createEntitySuccess,
    createEntityFailure,
    updateEntity,
    updateEntitySuccess,
    updateEntityFailure,
    updateOnlyEntity,
    updateOnlyEntitySuccess,
    updateOnlyEntityFailure,
    addOnlyEntity,
    addOnlyEntitySuccess,
    addOnlyEntityFailure,
    deleteEntity,
    deleteEntitySuccess,
    deleteEntityFailure,
    addUnreadToPaginationEntity,
    addUnreadToPaginationEntitySuccess,
    addUnreadToPaginationEntityFailure,
    unreadAllLiveNotifications,
    unreadAllLiveNotificationsSuccess,
    unreadAllLiveNotificationsFailure,
    addLiveNotifications,
    addLiveNotificationsSuccess,
    addLiveNotificationsFailure,
  };
}

function getApiFilters(filtersCustom) {
  const filters = { ...apiGetFiltersDefault };
  if (filtersCustom != null) {
    filters.include = filtersCustom.include != null ? filtersCustom.include : filters.include;
    filters.page = filtersCustom.page != null ? filtersCustom.page : filters.page;
    filters.limit = filtersCustom.limit != null ? filtersCustom.limit : filters.limit;
    filters.params = filtersCustom.params != null ? filtersCustom.params : filters.params;
    filters.filters = filtersCustom.filters != null ? filtersCustom.filters : filters.filters;
    filters.search = filtersCustom.search != null ? filtersCustom.search : filters.search;
    filters.sort = filtersCustom.sort != null ? filtersCustom.sort : filters.sort;
  }
  return filters;
}
/* function getApiUrl(apiUrlCustom) {
  const apiUrl = { ...apiUrlDataDefault };
  if (apiUrlCustom != null) {
    apiUrl.module =
      apiUrlCustom.module != null ? apiUrlCustom.module : apiUrl.module;
    apiUrl.entity =
      apiUrlCustom.entity != null ? apiUrlCustom.entity : apiUrl.entity;
    apiUrl.entities =
      apiUrlCustom.entities != null ? apiUrlCustom.entities : apiUrl.entities;
    apiUrl.many = apiUrlCustom.many != null ? apiUrlCustom.many : apiUrl.many;
  } 

 // const many = apiUrl.many;
  const {many} = apiUrl;
  let entityName =
    many && apiUrl.entities != null ? apiUrl.entities : apiUrl.entity;
  entityName = !many && entityName == null ? apiUrl.entities : entityName;
  let url = apiUrl.module != null ? `/${apiUrl.module}`:"";
  url += entityName != null ?  `/${entityName}`   : "";
  apiUrl.url = url;
  return apiUrl;
}  */

export function _fetchEntitiesService(dataSlice, dispatchEvents) {
  if (dataSlice && dataSlice.dataUrl) {
    dataSlice.dataUrl.many = true;
    const getFilters = getApiFilters(dataSlice.getFilters);

    return async (dispatch) => {
      const showPageLoading = dataSlice.dataList ? dataSlice.dataList.showPageLoading : true;
      dispatch(dispatchEvents.getEntities(showPageLoading));
      try {
        const apiParams = { dataUrl: dataSlice.dataUrl, filters: getFilters };
        const response = await Api.getEntities(apiParams);

        dispatch(dispatchEvents.getEntitiesSuccess(response.data));

        if (dataSlice.dataList && dataSlice.dataList.successHandle) {
          dispatch(dataSlice.dataList.successHandle);
        }
      } catch (error) {
        dispatch(dispatchEvents.getEntitiesFailure(error));
        if (dataSlice.errorHandle) {
          dispatch(dataSlice.errorHandle);
        }
      }
    };
  }
}

export function _createEntityService(dataSlice, dispatchEvents) {
  if (dataSlice && dataSlice.dataCreate && dataSlice.dataUrl) {
    dataSlice.dataUrl.many = false;
    const payload = dataSlice.dataCreate.data;
    const getFilters = getApiFilters(dataSlice.getFilters);
    return async (dispatch) => {
      dispatch(dispatchEvents.createEntity());
      try {
        const apiParams = {
          dataUrl: dataSlice.dataUrl,
          filters: getFilters,
          payload,
        };
        const response = await Api.saveEntity(apiParams);

        dispatch(dispatchEvents.createEntitySuccess(response.data));
        if (dataSlice.dataCreate.successHandle) {
          dispatch(dataSlice.dataCreate.successHandle);
        }
      } catch (error) {
        dispatch(dispatchEvents.createEntityFailure(error));
        if (dataSlice.errorHandle) {
          dispatch(dataSlice.errorHandle);
        }
      }
    };
  }
}

export function _createEntityServiceReturn(dataSlice, dispatchEvents) {
  if (dataSlice && dataSlice.dataCreate && dataSlice.dataUrl) {
    dataSlice.dataUrl.many = false;
    const payload = dataSlice.dataCreate.data;
    const getFilters = getApiFilters(dataSlice.getFilters);

    return (dispatch) => {
      dispatch(dispatchEvents.createEntity());

      return new Promise((resolve) => {
        const apiParams = {
          dataUrl: dataSlice.dataUrl,
          filters: getFilters,
          payload,
        };

        Api.saveEntity(apiParams)
          .then((response) => {
            dispatch(dispatchEvents.createEntitySuccess(response.data));
            if (dataSlice.dataCreate.successHandle) {
              dispatch(dataSlice.dataCreate.successHandle);
            }

            resolve(response.data.data);
          })
          .catch((error) => {
            console.log(error);

            dispatch(dispatchEvents.createEntityFailure(error));
            if (dataSlice.errorHandle) {
              dispatch(dataSlice.errorHandle);
            }
          });
      });
    };
  }
}

export function _updateEntityServicePost(dataSlice, dispatchEvents) {
  if (dataSlice && dataSlice.dataUpdate && dataSlice.dataUrl) {
    dataSlice.dataUrl.many = false;
    //    const dataUrl = getApiUrl(dataSlice.dataUrl);
    //  const id = dataSlice.dataUpdate.id;
    const { id } = dataSlice.dataUpdate;

    const payload = dataSlice.dataUpdate.data;
    const getFilters = getApiFilters(dataSlice.getFilters);

    return async (dispatch) => {
      dispatch(dispatchEvents.updateEntity());
      try {
        // const url = `${dataUrl.url}/${id}`;
        getFilters.params.id = id;
        const apiParams = {
          dataUrl: dataSlice.dataUrl,
          filters: getFilters,
          payload,
        };
        const response = await Api.updateEntityPost(apiParams);

        dispatch(dispatchEvents.updateEntitySuccess(response.data));
        if (dataSlice.dataUpdate.successHandle) {
          dispatch(dataSlice.dataUpdate.successHandle);
        }
      } catch (error) {
        dispatch(dispatchEvents.updateEntityFailure(error));
        if (dataSlice.errorHandle) {
          dispatch(dataSlice.errorHandle);
        }
      }
    };
  }
}

export function _updateEntityServicePostReturn(dataSlice, dispatchEvents) {
  if (dataSlice && dataSlice.dataUpdate && dataSlice.dataUrl) {
    dataSlice.dataUrl.many = false;
    //    const dataUrl = getApiUrl(dataSlice.dataUrl);
    //    const id = dataSlice.dataUpdate.id;

    const { id } = dataSlice.dataUpdate;
    const payload = dataSlice.dataUpdate.data;
    const getFilters = getApiFilters(dataSlice.getFilters);
    return (dispatch) => {
      dispatch(dispatchEvents.updateEntity());

      return new Promise((resolve) => {
        //   const url = `${dataUrl.url}/${id}`;
        getFilters.params.id = id;
        const apiParams = {
          dataUrl: dataSlice.dataUrl,
          filters: getFilters,
          payload,
        };

        Api.updateEntityPost(apiParams)
          .then((response) => {
            dispatch(dispatchEvents.updateEntitySuccess(response.data));
            if (dataSlice.dataUpdate.successHandle) {
              dispatch(dataSlice.dataUpdate.successHandle);
            }

            resolve(response.data.data);
          })
          .catch((error) => {
            console.log(error);

            dispatch(dispatchEvents.updateEntityFailure(error));
            if (dataSlice.errorHandle) {
              dispatch(dataSlice.errorHandle);
            }
          });
      });
    };
  }
}

export function _updateEntityService(dataSlice, dispatchEvents) {
  if (dataSlice && dataSlice.dataUpdate && dataSlice.dataUrl) {
    dataSlice.dataUrl.many = false;
    // const dataUrl = getApiUrl(dataSlice.dataUrl);
    //    const id = dataSlice.dataUpdate.id;
    const { id } = dataSlice.dataUpdate;
    const payload = dataSlice.dataUpdate.data;
    const getFilters = getApiFilters(dataSlice.getFilters);
    return async (dispatch) => {
      dispatch(dispatchEvents.updateEntity());
      try {
        getFilters.params.id = id;
        const apiParams = {
          dataUrl: dataSlice.dataUrl,
          filters: getFilters,
          payload,
        };
        const response = await Api.updateEntity(apiParams);
        dispatch(dispatchEvents.updateEntitySuccess(response.data));
        if (dataSlice.dataUpdate.successHandle) {
          dispatch(dataSlice.dataUpdate.successHandle);
        }
      } catch (error) {
        dispatch(dispatchEvents.updateEntityFailure(error));
        if (dataSlice.errorHandle) {
          dispatch(dataSlice.errorHandle);
        }
      }
    };
  }
}

export function _updateOnlyEntityService(payload, dispatchEvents) {
  return async (dispatch) => {
    dispatch(dispatchEvents.updateOnlyEntity());
    try {
      dispatch(dispatchEvents.updateOnlyEntitySuccess(payload));
    } catch (error) {
      dispatch(dispatchEvents.updateOnlyEntityFailure(error));
    }
  };
}

export function _addOnlyEntityService(payload, dispatchEvents) {
  return async (dispatch) => {
    dispatch(dispatchEvents.addOnlyEntity());
    try {
      dispatch(dispatchEvents.addOnlyEntitySuccess(payload));
    } catch (error) {
      dispatch(dispatchEvents.addOnlyEntityFailure(error));
    }
  };
}

export function _deleteOnlyEntityService(payload, dispatchEvents) {
  return async (dispatch) => {
    dispatch(dispatchEvents.deleteEntity());
    try {
      dispatch(dispatchEvents.deleteEntitySuccess(payload));
    } catch (error) {
      dispatch(dispatchEvents.deleteEntity(error));
    }
  };
}

export function _deleteEntityService(dataSlice, dispatchEvents) {
  if (dataSlice && dataSlice.dataDelete && dataSlice.dataUrl) {
    dataSlice.dataUrl.many = false;
    //    const id = dataSlice.dataDelete.id;
    // const dataUrl = getApiUrl(dataSlice.dataUrl);

    const { id } = dataSlice.dataDelete;
    const getFilters = getApiFilters(dataSlice.getFilters);

    return async (dispatch) => {
      dispatch(dispatchEvents.deleteEntity());
      try {
        getFilters.params.id = id;
        const apiParams = { dataUrl: dataSlice.dataUrl, filters: getFilters };
        await Api.deleteEntity(apiParams);

        dispatch(dispatchEvents.deleteEntitySuccess(id));

        if (dataSlice.dataDelete.successHandle) {
          dispatch(dataSlice.dataDelete.successHandle);
        }
      } catch (error) {
        dispatch(dispatchEvents.deleteEntityFailure(error));
        if (dataSlice.errorHandle) {
          dispatch(dataSlice.errorHandle);
        }
      }
    };
  }
}

export function _addUnreadToPaginationEntityService(payload, dispatchEvents) {
  return async (dispatch) => {
    dispatch(dispatchEvents.addUnreadToPaginationEntity());
    try {
      dispatch(dispatchEvents.addUnreadToPaginationEntitySuccess(payload));
    } catch (error) {
      dispatch(dispatchEvents.addUnreadToPaginationEntityFailure(error));
    }
  };
}

export function _unreadAllLiveNotifications(dispatchEvents) {
  return async (dispatch) => {
    dispatch(dispatchEvents.unreadAllLiveNotifications());
    try {
      dispatch(dispatchEvents.unreadAllLiveNotificationsSuccess());
    } catch (error) {
      dispatch(dispatchEvents.unreadAllLiveNotificationsFailure(error));
    }
  };
}
export function _addLiveNotifications(payload, dispatchEvents) {
  return async (dispatch) => {
    dispatch(dispatchEvents.addLiveNotifications());
    try {
      dispatch(dispatchEvents.addLiveNotificationsSuccess(payload));
    } catch (error) {
      dispatch(dispatchEvents.addLiveNotificationsFailure(error));
    }
  };
}
