import {
  newSlice,
  _fetchEntitiesService,
  _loadEvents,
  _addOnlyEntityService,
  _addUnreadToPaginationEntityService,
  _unreadAllLiveNotifications,
  _addLiveNotifications
} from './generic/sliceFunctions';

const entitiesName = "liveNotifications";

const entitiesSlice = newSlice(entitiesName);
const events = _loadEvents(entitiesSlice);

export const entitiesSelector = (state) => state[entitiesName];
export default entitiesSlice.reducer;

export function fetchEntitiesService(dataSlice) {
  dataSlice.dataUrl.code = "GET_LIVENOTIFICATIONS";
  dataSlice.dataUrl.oid = dataSlice.orgId ? dataSlice.orgId : null;

  return _fetchEntitiesService(dataSlice, events);
}

export function addOnlyEntityService(payload) {
  return _addOnlyEntityService(payload, events);
}

export function addUnreadToPaginationEntityService(payload) {
  return _addUnreadToPaginationEntityService(payload, events);
}
export function unreadAllLiveNotifications() {
  return _unreadAllLiveNotifications(events);
}

export function addLiveNotifications(payload) {
  return _addLiveNotifications(payload, events);
}




