// hooks
import useAuth from '../hooks/useAuth';
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar from './Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const { user } = useAuth();

  return (
    <Avatar
      src={user?.avatarUrl}
      alt={`${user?.fname} ${user?.lname}`}
      color={user?.avatarUrl ? 'default' : createAvatar(`${user?.fname} ${user?.lname}`).color}
      {...other}
    >
      {createAvatar(`${user?.fname} ${user?.lname}`).name}
    </Avatar>
  );
}
