import {
  newSlice,
  _fetchEntitiesService,
  _loadEvents,
  
} from "./generic/sliceFunctions";

const entitiesName = "permissions";

const entitiesSlice = newSlice(entitiesName);
const events = _loadEvents(entitiesSlice);

export const entitiesSelector = (state) => state[entitiesName];
export default entitiesSlice.reducer;

export function fetchEntitiesService(dataSlice) {
  dataSlice.dataUrl.code = "GET_PERMISSIONS";
  return _fetchEntitiesService(dataSlice, events);
}
