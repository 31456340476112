import { combineReducers } from 'redux';
// import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
/* import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar'; */
// import kanbanReducer from './slices/kanban';

// import usersReducer from './slices/usersSlice';
import departmentsDropdownReducer from './slices/departmentsDropdownSlice';
import usersDropdownReducer from './slices/usersDropdownSlice';
// import departmentsReducer from './slices/departmentsSlice';
import rolesReducer from './slices/rolesSlice';
import permissionsReducer from './slices/permissionsSlice';
// import callTypesReducer from './slices/callTypesSlice';
// import callProblemTypesReducer from './slices/callProblemTypesSlice';
import callProblemsDropdownReducer from './slices/callProblemsDropdownSlice';
import callTypesDropdownReducer from './slices/callTypesDropdownSlice';
// import callHoldReasonsReducer from './slices/callHoldReasonsSlice';

import locationContactsReducer from './slices/locationContactsSlice';
import locationsContactsReducer from './slices/locationsContactsSlice';

// import customersReducer from './slices/customersSlice';
import customerLocationsReducer from './slices/customerLocationsSlice';
import customersLocationsReducer from './slices/customersLocationsSlice';
import callsReducer from './slices/callsSlice';
import customerLocationsDropdownReducer from './slices/customerLocationsDropdownSlice';
import customerCallsReducer from './slices/customerCallsSlice';
import callsNoCompletedReducer from './slices/callsNoCompletedSlice';
import locationCallsReducer from './slices/locationCallsSlice';
// import organizationsReducer from './slices/organizationsSlice';
import liveNotificationsReducer from './slices/liveNotificationsSlice';

import organizationsDropdownReducer from './slices/organizationsDropdownSlice';
import callStatusDropdownReducer from './slices/callStatusDropdownSlice';
// import stockItemPartsReducer from './slices/stockItemPartsSlice';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

/* const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
}; */

const rootReducer = combineReducers({
  /* mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,

  product: persistReducer(productPersistConfig, productReducer), */
  // kanban: kanbanReducer,
  //  usersEdit: usersReducer,
  departmentsDropdown: departmentsDropdownReducer,
  usersDropdown: usersDropdownReducer,
  // departments: departmentsReducer,
  roles: rolesReducer,
  permissions: permissionsReducer,
  // callProblemTypes: callProblemTypesReducer,
  // callTypes: callTypesReducer,
  locationContact: locationContactsReducer,
  locationsContacts: locationsContactsReducer,
  // customers: customersReducer,
  customerLocations: customerLocationsReducer,
  customersLocations: customersLocationsReducer,
  calls: callsReducer,
  customerLocationsDropdown: customerLocationsDropdownReducer,
  customerCalls: customerCallsReducer,
  callProblemsDropdown: callProblemsDropdownReducer,
  callTypesDropdown: callTypesDropdownReducer,
  callsNoCompleted: callsNoCompletedReducer,
  locationCalls: locationCallsReducer,
  // callHoldReasons: callHoldReasonsReducer,
  // organizations: organizationsReducer,
  liveNotifications: liveNotificationsReducer,
  organizationsDropdown: organizationsDropdownReducer,
  callStatusDropdown: callStatusDropdownReducer,
  //  stockItemParts: stockItemPartsReducer,
});

export { rootPersistConfig, rootReducer };
