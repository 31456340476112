import {
    newSlice,
    _fetchEntitiesService,
    _loadEvents,    
    _deleteEntityService,
    _updateOnlyEntityService,
    _updateEntityServicePostReturn,
    _createEntityServiceReturn
  } from "./generic/sliceFunctions";
  
  const entitiesName = "locationContact";
  
  const entitiesSlice = newSlice(entitiesName);
  const events = _loadEvents(entitiesSlice);
  
  export const entitiesSelector = (state) => state[entitiesName];
  export default entitiesSlice.reducer;
  
  export function fetchEntitiesService(dataSlice) {
    dataSlice.dataUrl.code = "GET_LOCATIONCONTACTS";
   
    dataSlice.dataUrl.oid = dataSlice.oid ? dataSlice.oid : null;
    return _fetchEntitiesService(dataSlice, events);
  }
  export function createEntityService(dataSlice) {
    dataSlice.dataUrl.code = "POST_LOCATIONCONTACT";
    return _createEntityServiceReturn(dataSlice, events);
  }
  
  export function updateEntityService(dataSlice) {
    dataSlice.dataUrl.code = "PUT_LOCATIONCONTACT";
    return _updateEntityServicePostReturn(dataSlice, events);
  }
  
  export function deleteEntityService(dataSlice) {
    dataSlice.dataUrl.code = "DELETE_LOCATIONCONTACT";
    return _deleteEntityService(dataSlice, events);
  }
  export function updateOnlyEntityService(payload) {
    return _updateOnlyEntityService(payload, events);
  }
  