import {
    newSlice,
    _fetchEntitiesService,
    _loadEvents,
    _addOnlyEntityService    
  } from "./generic/sliceFunctions";
  
  const entitiesName = "departmentsDropdown";
  
  const entitiesSlice = newSlice(entitiesName);
  const events = _loadEvents(entitiesSlice);
  
  export const entitiesSelector = (state) => state[entitiesName];
  export default entitiesSlice.reducer;
  
  export function fetchEntitiesService(dataSlice) {
    dataSlice.dataUrl.code = "GET_DEPARTMENTSDROPDOWN";  
    return _fetchEntitiesService(dataSlice, events);
  }
  export function addOnlyEntityService(payload) {
    return _addOnlyEntityService(payload, events);
  }
 