import {
    newSlice,
    _fetchEntitiesService,
    _loadEvents,
   
  } from "./generic/sliceFunctions";
  
  const entitiesName = "customerLocationsDropdown";
  
  const entitiesSlice = newSlice(entitiesName);
  const events = _loadEvents(entitiesSlice);
  
  export const entitiesSelector = (state) => state[entitiesName];
  export default entitiesSlice.reducer;
  
  export function fetchEntitiesService(dataSlice) {
    dataSlice.dataUrl.code = "GET_CUSTOMERLOCATIONSDROPDOWN";
    dataSlice.dataUrl.oid = dataSlice.oid ? dataSlice.oid : null;  
    return _fetchEntitiesService(dataSlice, events);
  }
  
  