import { Suspense, lazy, useEffect } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import { isEmpty, filter } from 'lodash';
import useAuth from '../hooks/useAuth';
import CRMUtils from '../utils/crmUtils';
// layouts
// import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import { getBrowserToken } from '../utils/firebase';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { user } = useAuth();

  useEffect(() => {
    if (!isEmpty(user)) getBrowserToken(user.id);
  }, [user]);

  const routes = [
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: 'general',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: '/general/dashboard', element: <GeneralApp /> },
        { path: 'permission-denied', element: <PermissionDenied /> },
      ],
    },

    {
      path: 'frontdesk',
      permissions: ['menu_frontDesk'],
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'callscreate',
          element: <CallsCreateList />,
          permissions: ['callsystem_locations'],
        },
        {
          path: 'reviewcalls',
          element: <ReviewCallsList />,
          permissions: ['callsystem_locations'],
        },
      ],
    },
    {
      path: 'billing',
      permissions: ['menu_billing'],
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'calls',
          element: <BillingCalls />,
          permissions: ['callsystem_billing'],
        },
      ],
    },
    {
      path: 'callsystem',
      permissions: ['menu_callSystem'],
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'callsmanager',
          element: <CallsManagerList />,
          permissions: ['callsystem_assign'],
        },
      ],
    },

    {
      path: 'reports',
      permissions: ['menu_reports'],
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'calls',
          element: <ReportCalls />,
          permissions: ['report_calls'],
        },
        {
          path: 'pendingcalls',
          element: <PendingOnCallReport />,
          permissions: ['report_calls'],
        },
        {
          path: 'completedcalls',
          element: <CompletedCalls />,
          permissions: ['report_pendingApproval'],
        },
      ],
    },
    {
      path: 'management',
      permissions: ['menu_management'],
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'customers',
          element: <CustomersList />,
          permissions: ['management_customers'],
        },
        {
          path: 'users',
          element: <UsersList />,
          permissions: ['management_users'],
        },
        {
          path: 'roles',
          element: <RolesList />,
          permissions: ['management_roles'],
        },
        {
          path: 'departments',
          element: <DepartmentList />,
          permissions: ['management_departments'],
        },
        {
          path: 'stockitemparts',
          element: <StockItemParts />,
          permissions: ['management_stockItemPart'],
        },
        {
          path: 'calltypes',
          element: <CallTypes />,
          permissions: ['management_callTypes'],
        },
        {
          path: 'callproblemtypes',
          element: <CallProblemTypes />,
          permissions: ['management_callProblemTypes'],
        },
        {
          path: 'callholdreasons',
          element: <CallHoldReasons />,
          permissions: ['management_callHoldReasons'],
        },
        {
          path: 'techoncall',
          element: <TechOnCall />,
          //  permissions: ["management_callHoldReasons"],
        },

        {
          path: 'organizations',
          element: <Organizations />,
          permissions: ['management_settings'],
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <Navigate to="/general/dashboard" replace />,
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ];

  let permissionRoutes = routes;

  if (user) {
    const permissionTempRoutes = filter(routes, (item) => {
      return item && !isEmpty(item.permissions) ? CRMUtils.userHasPermission(user, item.permissions) : true;
    });

    permissionRoutes = permissionTempRoutes.map((item, index) => {
      return {
        ...item,
        children: item.children
          ? item.children.filter((item) => {
              return !isEmpty(item.permissions) ? CRMUtils.userHasPermission(user, item.permissions) : true;
            })
          : null,
      };
    });
  }

  return useRoutes(permissionRoutes);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// DASHBOARD

// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(lazy(() => import('../pages/dashboard/PermissionDenied')));

// MAIN

const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));

// MANAGEMENT
const UsersList = Loadable(lazy(() => import('../pages/management/users')));
const DepartmentList = Loadable(lazy(() => import('../pages/management/departments')));

const RolesList = Loadable(lazy(() => import('../pages/management/roles')));
const CallProblemTypes = Loadable(lazy(() => import('../pages/management/callProblemTypes')));

const StockItemParts = Loadable(lazy(() => import('../pages/management/stockItemParts')));
const CallTypes = Loadable(lazy(() => import('../pages/management/callTypes')));

const CustomersList = Loadable(lazy(() => import('../pages/customers/customers')));

const CallsManagerList = Loadable(lazy(() => import('../pages/callsystem/callsmanager/CallsManagerList')));

const CallsCreateList = Loadable(lazy(() => import('../pages/callsystem/callscreate/CallsCreateList')));
const ReviewCallsList = Loadable(lazy(() => import('../pages/callsystem/reviewCalls/ReviewCallsList')));
const ReportCalls = Loadable(lazy(() => import('../pages/reports/calls/CallsReport')));
const PendingOnCallReport = Loadable(lazy(() => import('../pages/reports/pendingOnCall/PendingOnCallReport')));
const CallHoldReasons = Loadable(lazy(() => import('../pages/management/callHoldReasons')));
const Organizations = Loadable(lazy(() => import('../pages/management/organizations')));

const TechOnCall = Loadable(lazy(() => import('../pages/management/techOnCall/TechOnCallList')));
const BillingCalls = Loadable(lazy(() => import('../pages/callsystem/billing/BillingCallsList')));
const CompletedCalls = Loadable(lazy(() => import('../pages/reports/completedCalls/CompletedCallsList')));
