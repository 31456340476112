// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/general';
const ROOTS_MANAGEMENT = '/management';

const ROOTS_CALLSYSTEM = '/callsystem';
const ROOTS_FRONTDESK = '/frontdesk';
const ROOTS_BILLING = '/billing';

const ROOTS_REPORTS = '/reports';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    dashboard: path(ROOTS_DASHBOARD, '/dashboard'),
  },

  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
};

export const PATH_MANAGEMENT = {
  root: ROOTS_MANAGEMENT,

  customers: {
    root: path(ROOTS_MANAGEMENT, '/customers'),
  },
  users: {
    root: path(ROOTS_MANAGEMENT, '/users'),
    account: path(ROOTS_MANAGEMENT, '/user/account'),
  },
  departments: {
    root: path(ROOTS_MANAGEMENT, '/departments'),
  },
  roles: {
    root: path(ROOTS_MANAGEMENT, '/roles'),
  },
  callTypes: {
    root: path(ROOTS_MANAGEMENT, '/calltypes'),
  },
  stockItemParts: {
    root: path(ROOTS_MANAGEMENT, '/stockitemparts'),
  },
  callProblemTypes: {
    root: path(ROOTS_MANAGEMENT, '/callproblemtypes'),
  },
  callHoldReasons: {
    root: path(ROOTS_MANAGEMENT, '/callholdreasons'),
  },
  techOnCall: {
    root: path(ROOTS_MANAGEMENT, '/techoncall'),
  },
  organizations: {
    root: path(ROOTS_MANAGEMENT, '/organizations'),
  },
};

export const PATH_FRONTDESK = {
  root: ROOTS_FRONTDESK,
  callcreate: {
    root: path(ROOTS_FRONTDESK, '/callscreate'),
  },
  reviewcalls: {
    root: path(ROOTS_FRONTDESK, '/reviewcalls'),
  },
};

export const PATH_BILLING = {
  root: ROOTS_BILLING,
  calls: {
    root: path(ROOTS_BILLING, '/calls'),
  },
};

export const PATH_CALLSYSTEM = {
  root: ROOTS_CALLSYSTEM,
  callmanager: {
    root: path(ROOTS_CALLSYSTEM, '/callsmanager'),
  },
};

export const PATH_REPORTS = {
  root: ROOTS_REPORTS,
  calls: {
    root: path(ROOTS_REPORTS, '/calls'),
  },
  pendingcalls: {
    root: path(ROOTS_REPORTS, '/pendingcalls'),
  },
  completedcalls: {
    root: path(ROOTS_REPORTS, '/completedcalls'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
