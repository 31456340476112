// icons
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import SettingsAccessibilityRoundedIcon from '@mui/icons-material/SettingsAccessibilityRounded';
import SupervisedUserCircleRoundedIcon from '@mui/icons-material/SupervisedUserCircleRounded';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import WorkspacesRoundedIcon from '@mui/icons-material/WorkspacesRounded';
import InventoryIcon from '@mui/icons-material/Inventory';

import PersonPinIcon from '@mui/icons-material/PersonPin';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';

import { isEmpty, filter } from 'lodash';
import useAuth from '../../../hooks/useAuth';
import CRMUtils from '../../../utils/crmUtils';

// routes
import {
  PATH_DASHBOARD,
  PATH_MANAGEMENT,
  PATH_CALLSYSTEM,
  PATH_REPORTS,
  PATH_FRONTDESK,
  PATH_BILLING,
} from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import Iconify from '../../../components/Iconify';
import SvgIconStyle from '../../../components/SvgIconStyle';

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
};

// ----------------------------------------------------------------------

export default function NavConfig() {
  const { user } = useAuth();

  const navConfigArray = [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      subheader: 'general',
      items: [{ title: 'Dashboard', path: PATH_DASHBOARD.general.dashboard, icon: ICONS.dashboard }],
    },
    // FRONT DESK
    {
      subheader: 'front desk',
      permissions: ['menu_frontDesk'],
      items: [
        {
          title: 'Locations',
          path: PATH_FRONTDESK.callcreate.root,
          icon: <Iconify icon={'healthicons:call-centre'} />,
          permissions: ['callsystem_locations'],
        },
        {
          title: 'Review Calls',
          path: PATH_FRONTDESK.reviewcalls.root,
          icon: <Iconify icon={'mdi:customer-service'} />,
          permissions: ['callsystem_locations'],
        },
      ],
    },
    // BILLING
    {
      subheader: 'billing',
      permissions: ['menu_billing'],
      items: [
        {
          title: 'Calls',
          path: PATH_BILLING.calls.root,
          icon: <Iconify icon={'healthicons:money-bag'} />,
          permissions: ['callsystem_billing'],
        },
      ],
    },
    // CALL SYSTEM
    {
      subheader: 'call system',
      permissions: ['menu_callSystem'],
      items: [
        {
          title: 'Assign',
          path: PATH_CALLSYSTEM.callmanager.root,
          icon: <Iconify icon={'mdi:monitor-dashboard'} />,
          permissions: ['callsystem_assign'],
        },
      ],
    },
    // REPORTS
    {
      subheader: 'reports',
      permissions: ['menu_reports'],
      items: [
        {
          title: 'Pending On Call',
          path: PATH_REPORTS.pendingcalls.root,
          icon: <Iconify icon={'fluent:people-call-20-filled'} />,
          permissions: ['report_calls'],
        },
        {
          title: 'Pending Approval',
          path: PATH_REPORTS.completedcalls.root,
          icon: <Iconify icon={'pajamas:review-warning'} />,
          permissions: ['report_pendingApproval'],
        },
        {
          title: 'Calls',
          path: PATH_REPORTS.calls.root,
          icon: <Iconify icon={'fluent:video-person-call-32-filled'} />,
          permissions: ['report_calls'],
        },
      ],
    },
    // MANAGEMENT
    // ----------------------------------------------------------------------
    {
      subheader: 'management',
      permissions: ['menu_management'],
      items: [
        {
          title: 'Customers',
          path: PATH_MANAGEMENT.customers.root,
          icon: <PersonPinIcon />,
          permissions: ['menu_management'],
        },
        {
          title: 'On Call Calendar',
          path: PATH_MANAGEMENT.techOnCall.root,
          icon: <CalendarMonthRoundedIcon />,
          permissions: ['menu_management'],
        },
        {
          title: 'Users & Roles',
          path: '#1',
          icon: <PeopleAltRoundedIcon />,
          permissions: ['management_roles', 'management_roles'],
          children: [
            {
              title: 'Users',
              path: PATH_MANAGEMENT.users.root,
              permissions: ['management_users'],
            },
            {
              title: 'Roles',
              path: PATH_MANAGEMENT.roles.root,
              permissions: ['management_roles'],
            },
          ],
        },
        {
          title: 'Departments',
          path: PATH_MANAGEMENT.departments.root,
          icon: <SupervisedUserCircleRoundedIcon />,
          permissions: ['management_departments'],
        },
        {
          title: 'Organizations',
          path: PATH_MANAGEMENT.organizations.root,
          icon: <ApartmentRoundedIcon />,
          permissions: ['management_settings'],
        },
        {
          title: 'Inventory',
          path: '#2',
          icon: <InventoryIcon />,
          permissions: ['management_inventory'],
          children: [
            {
              title: 'Stock Item Parts',
              path: PATH_MANAGEMENT.stockItemParts.root,
              permissions: ['management_stockItemPart'],
            },
          ],
        },
        {
          title: 'Call Settings',
          path: '#3',
          icon: <WorkspacesRoundedIcon />,
          permissions: ['management_callTypes', 'management_callProblemTypes'],
          children: [
            {
              title: 'Call Types',
              path: PATH_MANAGEMENT.callTypes.root,
              permissions: ['management_callTypes'],
            },
            {
              title: 'Call Problem Types',
              path: PATH_MANAGEMENT.callProblemTypes.root,
              permissions: ['management_callProblemTypes'],
            },
            {
              title: 'Call Hold Reasons',
              path: PATH_MANAGEMENT.callHoldReasons.root,
              permissions: ['management_callHoldReasons'],
            },
          ],
        },
      ],
    },
  ];
  let navConfigPermission = [];

  if (user) {
    const navConfigTempPermission = filter(navConfigArray, (item) => {
      return item && !isEmpty(item.permissions) ? CRMUtils.userHasPermission(user, item.permissions) : true;
    });

    const navConfigTemp2Permission = navConfigTempPermission.map((item, index) => {
      return {
        ...item,
        items: item.items
          ? item.items.filter((item) => {
              return !isEmpty(item.permissions) ? CRMUtils.userHasPermission(user, item.permissions) : true;
            })
          : null,
      };
    });

    navConfigPermission = navConfigTemp2Permission.map((permission, index) => {
      return {
        ...permission,
        items: permission.items
          ? permission.items.map((item, index) => {
              return {
                ...item,
                children: item.children
                  ? item.children.filter((children) => {
                      return !isEmpty(children.permissions)
                        ? CRMUtils.userHasPermission(user, children.permissions)
                        : true;
                    })
                  : null,
              };
            })
          : null,
      };
    });
  }

  return navConfigPermission;
}
