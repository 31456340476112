import {
  newSlice,
  _fetchEntitiesService,
  _loadEvents,
  _createEntityService,
  _updateEntityServicePost,
  _deleteEntityService,
  _updateOnlyEntityService,
  _deleteOnlyEntityService,
  _addOnlyEntityService,
} from './generic/sliceFunctions';

const entitiesName = 'callsNoCompleted';

const entitiesSlice = newSlice(entitiesName);
const events = _loadEvents(entitiesSlice);

export const entitiesSelector = (state) => state[entitiesName];
export default entitiesSlice.reducer;

export function fetchEntitiesService(dataSlice) {
  dataSlice.dataUrl.code = 'GET_CALLSNO';

  return _fetchEntitiesService(dataSlice, events);
}

export function createEntityService(dataSlice) {
  dataSlice.dataUrl.code = 'POST_CALL';
  return _createEntityService(dataSlice, events);
}

export function updateEntityService(dataSlice) {
  dataSlice.dataUrl.code = 'PUT_CALL';
  return _updateEntityServicePost(dataSlice, events);
}

export function deleteEntityService(dataSlice) {
  dataSlice.dataUrl.code = 'DELETE_CALL';
  return _deleteEntityService(dataSlice, events);
}
export function updateOnlyEntityService(payload) {
  return _updateOnlyEntityService(payload, events);
}

export function deleteOnlyEntityService(id) {
  return _deleteOnlyEntityService(id, events);
}

export function addOnlyEntityService(payload) {
  return _addOnlyEntityService(payload, events);
}
