import {
    newSlice,
    _fetchEntitiesService,
    _loadEvents,
  
  } from "./generic/sliceFunctions";
  
  const entitiesName = "callTypesDropdown";
  
  const entitiesSlice = newSlice(entitiesName);
  const events = _loadEvents(entitiesSlice);
  
  export const entitiesSelector = (state) => state[entitiesName];
  export default entitiesSlice.reducer;
  
  export function fetchEntitiesService(dataSlice) {
    dataSlice.dataUrl.oid = dataSlice.orgId ? dataSlice.orgId : null;
    dataSlice.dataUrl.code = "GET_CALLTYPES";
   
  
    return _fetchEntitiesService(dataSlice, events);
  }
  
  