import React from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { Button } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";

import axios from "axios";
import Image from "./Image";
import useAuth from "../hooks/useAuth";



function GoogleLoginButton(props) {
  // const { loginGoogle } = useAuth();
  const { loginGoogle2 } = useAuth();
  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      loginGoogle2(tokenResponse.access_token);
      // const userInfo = await axios
      //   .get("https://www.googleapis.com/oauth2/v3/userinfo", {
      //     headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
      //   })
      //   .then((res) => res.data);
        
   //   loginGoogle(userInfo.email);
    },
    onError: (errorResponse) => console.log(errorResponse),
  });

  return (

    <Button variant="outlined" startIcon={<Image disabledEffect alt="Google Login" src={"/logo/g-login.png"} />}
    onClick={() => googleLogin()}>
        Google Login
    </Button>

   
  );
}

export default GoogleLoginButton;
