import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Checkbox, FormControlLabel, FormGroup, Grid } from '@mui/material';

// ----------------------------------------------------------------------

RHFCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
};

export function RHFCheckbox({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          render={({ field }) => <Checkbox {...field} checked={field.value} />}
        />
      }
      {...other}
    />
  );
}

// ----------------------------------------------------------------------

RHFMultiCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

export function RHFMultiCheckbox({ name, options, grid = 12, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const onSelected = (option) =>
          field.value.includes(option) ? field.value.filter((value) => value !== option) : [...field.value, option];

        return (
          <FormGroup>
            <Grid container>
              {options.map((option, key) => (
                <Grid item xs={grid} key={`grid-${key}-${option.value}`}>
                  <FormControlLabel
                    key={`${option.value}-${key}`}
                    control={
                      <Checkbox
                        checked={field.value.includes(option.value)}
                        onChange={() => field.onChange(onSelected(option.value))}
                      />
                    }
                    label={option.label}
                    {...other}
                  />
                </Grid>
              ))}
            </Grid>
          </FormGroup>
        );
      }}
    />
  );
}
