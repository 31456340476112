import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem } from '@mui/material';
// routes
import { PATH_DASHBOARD, PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import MyAvatar from '../../../components/MyAvatar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import CRMUtils from '../../../utils/crmUtils';
import Api from '../../../lib/api';

import { fetchEntitiesService as fetchOrganizationsDropdownService } from '../../../redux/slices/organizationsDropdownSlice';
// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    linkTo: '/',
  },
];

const dataSliceOrganizationsDropdown = {
  stateListName: 'organizationsDropdown',

  dataUrl: {},

  getFilters: {
    include: [],
    params: [],
    filters: [],
  },

  errorHandle: null,
};

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, logout, update } = useAuth();

  const userCanChangeOrganization = CRMUtils.userHasPermission(user, ['organization_change']);

  const { organizationsDropdown, organizationsDropdownLoading } = useSelector((state) => state.organizationsDropdown);

  const isMountedRef = useIsMountedRef();

  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(null);

  const [open2, setOpen2] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };
  const handleOpen2 = (event) => {
    setOpen2(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleClose2 = () => {
    setOpen2(null);
  };

  const handleLogout = async () => {
    try {
      const token = localStorage.getItem('deviceToken');
      if (token) {
        await Api.unregisterToken(user.id, token, 'web');
      }

      await logout();

      navigate(PATH_AUTH.login, { replace: true });

      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };
  useEffect(() => {
    if (isEmpty(organizationsDropdown && !organizationsDropdownLoading)) {
      dispatch(fetchOrganizationsDropdownService(dataSliceOrganizationsDropdown));
    }
  }, []);

  const renderOrganizationsMenu = () => {
    return organizationsDropdown
      ? organizationsDropdown.filter((organization) => {
          const userBuildings = user.userOrganizations.data.map((userOrganization) => {
            return userOrganization.id;
          });
          return userBuildings.includes(organization.id);
        })
      : [];
  };

  const changeORG = async (id) => {
    // console.log('changeORG', id);
    const payload = {
      organization_id: id,
    };
    const { data } = await Api.changeOrganization(user.id, payload);

    if (data) {
      update(data);
      navigate(0);
    }
  };
  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyAvatar />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {`${user?.fname} ${user?.lname}`}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>
        {userCanChangeOrganization && (
          <MenuItem
            onClick={(event) => {
              handleOpen2(event);
            }}
            sx={{ m: 1 }}
          >
            Select Organization
          </MenuItem>
        )}

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>

      <MenuPopover
        open={Boolean(open2)}
        anchorEl={open2}
        onClose={handleClose2}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
          backgroundColor: '#e0f7fa',
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        disabledArrow
      >
        {organizationsDropdown
          ? renderOrganizationsMenu().map((organization) => {
              return (
                <MenuItem
                  id={organization.id}
                  key={organization.id}
                  onClick={() => {
                    changeORG(organization.id);
                    handleClose2();
                    handleClose();
                  }}
                  sx={{ m: 1 }}
                >
                  {organization.name}
                </MenuItem>
              );
            })
          : ''}
      </MenuPopover>
    </>
  );
}
