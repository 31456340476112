import {
    newSlice,
    _fetchEntitiesService,
    _loadEvents,
    _createEntityService,
    _updateEntityServicePost,
    _deleteEntityService,
    _updateOnlyEntityService,
  } from "./generic/sliceFunctions";
  
  const entitiesName = "customersLocations";
  
  const entitiesSlice = newSlice(entitiesName);
  const events = _loadEvents(entitiesSlice);
  
  export const entitiesSelector = (state) => state[entitiesName];
  export default entitiesSlice.reducer;
  
  export function fetchEntitiesService(dataSlice) {
    dataSlice.dataUrl.code = "GET_CUSTOMERSLOCATIONS";
   
    dataSlice.dataUrl.oid = dataSlice.orgId ? dataSlice.orgId : null;
    return _fetchEntitiesService(dataSlice, events);
  }
  export function createEntityService(dataSlice) {
    dataSlice.dataUrl.code = "POST_CUSTOMERLOCATION";
    return _createEntityService(dataSlice, events);
  }
  
  export function updateEntityService(dataSlice) {
    dataSlice.dataUrl.code = "PUT_CUSTOMERLOCATION";
    return _updateEntityServicePost(dataSlice, events);
  }
  
  export function deleteEntityService(dataSlice) {
    dataSlice.dataUrl.code = "DELETE_CUSTOMERLOCATION";
    return _deleteEntityService(dataSlice, events);
  }
  export function updateOnlyEntityService(payload) {
    return _updateOnlyEntityService(payload, events);
  }
  