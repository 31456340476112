import { newSlice, _fetchEntitiesService, _loadEvents } from './generic/sliceFunctions';

const entitiesName = 'organizationsDropdown';

const entitiesSlice = newSlice(entitiesName);
const events = _loadEvents(entitiesSlice);

export const entitiesSelector = (state) => state[entitiesName];
export default entitiesSlice.reducer;

export function fetchEntitiesService(dataSlice) {
  dataSlice.dataUrl.code = 'GET_ORGANIZATIONSDROPDOWN';
  return _fetchEntitiesService(dataSlice, events);
}
