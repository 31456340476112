import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import Api from "../lib/api";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDh2T1U1hNLT9HyiBj5j3xvCHkiX-kI-m0",
  authDomain: "petrosolutions-de66b.firebaseapp.com",
  projectId: "petrosolutions-de66b",
  storageBucket: "petrosolutions-de66b.appspot.com",
  messagingSenderId: "536540615527",
  appId: "1:536540615527:web:ad273e8cdbcd61afc195c2",
  measurementId: "G-4XNDYJTDRF"
};


initializeApp(firebaseConfig);

const publicKey ="BP9RYZmNbwfuJbd1wjBJZ8blRaRvHpUVkVTUvKoDCbtS2WZPoZIsg78MTPq6lcixM5Nsf2tEa4dOcBcCH-s7yK8";


export const getBrowserToken = async (userId) => {
  const messaging = getMessaging();
  getToken(messaging, {
    vapidKey: publicKey,
  })
    .then((currentToken) => {
      if (currentToken) {

        localStorage.setItem("deviceToken", currentToken);
        const payload = {
          token: currentToken,
          os: "web",
        };

          Api.registerToken(userId, payload);
          return currentToken;
        
   
      }
    
        console.log(
          "No registration token available. Request permission to generate one."
        );
        // ...
      
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

export const onMessageListener = () => {
  const messaging = getMessaging();
  onMessage(messaging, (payload) => {
    console.log("Message received. ", payload);
    return payload;
  });
};
