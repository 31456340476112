import {
    newSlice,
    _fetchEntitiesService,
    _loadEvents,
    _addOnlyEntityService  
  } from "./generic/sliceFunctions";
  
  const entitiesName = "usersDropdown";
  
  const entitiesSlice = newSlice(entitiesName);
  const events = _loadEvents(entitiesSlice);
  
  export const entitiesSelector = (state) => state[entitiesName];
  export default entitiesSlice.reducer;
  
  export function fetchEntitiesService(dataSlice) {
    dataSlice.dataUrl.oid = dataSlice.orgId ? dataSlice.orgId : null;
    dataSlice.dataUrl.code = "GET_USERSDROPDOWN"; 
  
    return _fetchEntitiesService(dataSlice, events);
  }
  export function addOnlyEntityService(payload) {
    return _addOnlyEntityService(payload, events);
  }
 
  